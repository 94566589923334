import request from '@/common/axios';
import store from '@/store';

// 查询套餐列表
export function fetchProductList(data) {
  return request({
    url: '/web/admin/goods/adminGoodsPage',
    method: 'POST',
    data: data,
  });
}

// 添加套餐
export function addProduct(data) {
  return request({
    url: '/web/admin/goods/addGoods',
    method: 'POST',
    data: {
      ...data,
    }
  });
}

// 上架下架
export function disabledProduct(data) {
  return request({
    url: '/web/admin/goods/downGoods',
    method: 'POST',
    data: data
  });
}

export function ableProduct(data){
  return request({
    url: '/web/admin/goods/upGoods',
    method: 'POST',
    data: data
  });
}

// 修改套餐
export function updateProduct(data) {
  return request({
    url: '/web/admin/goods/updateGoods',
    method: 'POST',
    data:data
  });
}

// 刪除套餐
export function delProduct(data) {
  return request({
    url: '/platform',
    method: 'POST',
    data: {
      "iReqCode": 802,
      oReqData: {
        ...data
      }
    }
  });
}
